exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reports-2022-06-27-annual-report-2021-index-js": () => import("./../../../src/pages/reports/2022/06/27/annual-report-2021/index.js" /* webpackChunkName: "component---src-pages-reports-2022-06-27-annual-report-2021-index-js" */),
  "component---src-pages-reports-2022-12-05-our-strategy-index-js": () => import("./../../../src/pages/reports/2022/12/05/our-strategy/index.js" /* webpackChunkName: "component---src-pages-reports-2022-12-05-our-strategy-index-js" */),
  "component---src-pages-reports-2023-06-27-annual-report-2022-index-js": () => import("./../../../src/pages/reports/2023/06/27/annual-report-2022/index.js" /* webpackChunkName: "component---src-pages-reports-2023-06-27-annual-report-2022-index-js" */),
  "component---src-pages-reports-2024-07-25-annual-report-2023-index-js": () => import("./../../../src/pages/reports/2024/07/25/annual-report-2023/index.js" /* webpackChunkName: "component---src-pages-reports-2024-07-25-annual-report-2023-index-js" */),
  "component---src-pages-stories-2020-bangladesh-amphan-hidden-climate-costs-index-js": () => import("./../../../src/pages/stories/2020/bangladesh-amphan-hidden-climate-costs/index.js" /* webpackChunkName: "component---src-pages-stories-2020-bangladesh-amphan-hidden-climate-costs-index-js" */),
  "component---src-pages-stories-2021-11-17-mediterranean-migration-europe-index-js": () => import("./../../../src/pages/stories/2021/11/17/mediterranean-migration-europe/index.js" /* webpackChunkName: "component---src-pages-stories-2021-11-17-mediterranean-migration-europe-index-js" */),
  "component---src-pages-stories-2021-12-21-bangladesh-rohinyga-camp-women-illustration-index-js": () => import("./../../../src/pages/stories/2021/12/21/bangladesh-rohinyga-camp-women-illustration/index.js" /* webpackChunkName: "component---src-pages-stories-2021-12-21-bangladesh-rohinyga-camp-women-illustration-index-js" */),
  "component---src-pages-stories-2021-12-8-drawing-syrias-trauma-index-js": () => import("./../../../src/pages/stories/2021/12/8/drawing-syrias-trauma/index.js" /* webpackChunkName: "component---src-pages-stories-2021-12-8-drawing-syrias-trauma-index-js" */),
  "component---src-pages-stories-2021-a-decade-of-war-in-syria-index-js": () => import("./../../../src/pages/stories/2021/a-decade-of-war-in-syria/index.js" /* webpackChunkName: "component---src-pages-stories-2021-a-decade-of-war-in-syria-index-js" */),
  "component---src-pages-stories-2022-05-10-us-asylum-darien-gap-cuba-central-america-mexico-index-js": () => import("./../../../src/pages/stories/2022/05/10/us-asylum-darien-gap-cuba-central-america-mexico/index.js" /* webpackChunkName: "component---src-pages-stories-2022-05-10-us-asylum-darien-gap-cuba-central-america-mexico-index-js" */),
  "component---src-pages-stories-2022-07-28-whatsapp-lebanon-index-js": () => import("./../../../src/pages/stories/2022/07/28/whatsapp-lebanon/index.js" /* webpackChunkName: "component---src-pages-stories-2022-07-28-whatsapp-lebanon-index-js" */),
  "component---src-pages-stories-2022-07-28-wtsb-lbnn-index-js": () => import("./../../../src/pages/stories/2022/07/28/wtsb-lbnn/index.js" /* webpackChunkName: "component---src-pages-stories-2022-07-28-wtsb-lbnn-index-js" */),
  "component---src-pages-stories-2023-11-28-art-time-crisis-drawing-derna-index-js": () => import("./../../../src/pages/stories/2023/11/28/art-time-crisis-drawing-derna/index.js" /* webpackChunkName: "component---src-pages-stories-2023-11-28-art-time-crisis-drawing-derna-index-js" */)
}

